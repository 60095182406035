<span class="title">{{ "login.loginTitle" | translate }}</span>
<form [formGroup]="form" novalidate>
  <section>
    <mat-form-field class="full-width">
      <mat-label>{{ "login.emailAddressPlaceholder" | translate }}</mat-label>
      <input
        autocomplete="email"
        matInput
        #email
        type="email"
        formControlName="email"
      />
      @if (form.controls.email.errors?.required) {
        <mat-error>
          {{ "login.emailAddressRequired" | translate }}
        </mat-error>
      } @else if (form.controls.email.errors?.email) {
        <mat-error>
          {{ "login.emailAddressInvalid" | translate }}
        </mat-error>
      }
    </mat-form-field>
    <mat-form-field
      class="full-width password-field"
      [ngClass]="{ hidden: !showPasswordField() }"
    >
      <mat-label>{{ "login.passwordPlaceholder" | translate }}</mat-label>
      <input
        autocomplete="current-password"
        matInput
        #password
        type="password"
        formControlName="password"
      />
      <mat-error class="password-error">
        {{ "login.passwordRequired" | translate }}
      </mat-error>
    </mat-form-field>
    <a
      class="extra-field forgot-pass"
      [ngClass]="{ hidden: !showPasswordField() }"
      [routerLink]="['/recover-password']"
    >
      {{ "login.forgotPassword" | translate }}
    </a>
  </section>
  <section>
    <button
      class="btn btn--primary full-width"
      [disabled]="loading()"
      (click)="login()"
    >
      {{ "login.loginButton" | translate }}
    </button>
    <div class="switch-form">
      <span>{{ "login.createAccount" | translate }}</span>
      <a [routerLink]="'/signup'">
        <strong>{{ "invite.signUp" | translate }}</strong>
      </a>
    </div>
  </section>
</form>
