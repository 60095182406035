import { NgClass } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { fromEvent, lastValueFrom, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { GtmService } from "../../shared/analytics/gtm.service";
import { SharedPipesModule } from "../../shared/pipes/shared-pipes.module";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { FeatureFlagsService } from "../../shared/services/feature-flags.service";
import { IntercomService } from "../../shared/services/intercom.service";
import { NotificationService } from "../../shared/services/notification.service";
import { assert } from "../../shared/utils/assert";
import { CustomFormValidators } from "../../shared/validators/custom-form-validators";
import { AuthSection } from "../auth-page/auth-page.component";
import { UserAccessService } from "../user-access.service";

interface LoginForm {
  readonly email: FormControl<string | null>;
  readonly password: FormControl<string | null>;
}

@Component({
  standalone: true,
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrl: "../auth.scss",
  imports: [
    NgClass,
    MatError,
    RouterLink,
    TranslateModule,
    MatLabel,
    MatFormField,
    FormsModule,
    MatInput,
    SharedPipesModule,
    ReactiveFormsModule,
  ],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() public readonly changeSectionTo = new EventEmitter<AuthSection>();

  protected readonly loading = signal(false);
  protected readonly showPasswordField = signal(false);
  protected returnUrl?: string;
  @ViewChild("email") protected emailField?: ElementRef<HTMLInputElement>;
  @ViewChild("password") protected passwordField?: ElementRef<HTMLInputElement>;
  private readonly gtmService = inject(GtmService);

  private readonly userAccessService = inject(UserAccessService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly featureFlagsService = inject(FeatureFlagsService);
  private readonly intercomService = inject(IntercomService);
  private readonly notificationService = inject(NotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  protected readonly form: FormGroup<LoginForm>;

  private inputChangeSubscription: Subscription | null = null;

  constructor() {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl ?? "welcome";
    });

    this.form = new FormGroup<LoginForm>({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        CustomFormValidators.pattern(CustomFormValidators.PASSWORD_PATTERN),
      ]),
    });
  }

  public ngOnInit(): void {
    this.authenticationService.logout();
  }

  public ngAfterViewInit(): void {
    const input = this.emailField?.nativeElement;
    if (!input) {
      return;
    }

    // Force value update on input change, to cover autofill cases
    this.inputChangeSubscription = fromEvent(input, "change")
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.forceEmailInputUpdate(input);
      });

    setTimeout(() => {
      this.forceEmailInputUpdate(input);
    }, 200);
  }

  private forceEmailInputUpdate(input: HTMLInputElement): void {
    if (
      this.form.controls.email &&
      this.form.controls.email.value !== input.value
    ) {
      this.form.controls.email.setValue(input.value);
      this.form.controls.email.updateValueAndValidity();
    }
  }

  public ngOnDestroy(): void {
    this.inputChangeSubscription?.unsubscribe();
  }

  protected login(): void {
    this.form.updateValueAndValidity();
    // For some reason, the form validity is not updated when autofill is used
    // We are instead checking specifically for the form fields validity
    if (
      !this.form.controls.email.valid ||
      (this.showPasswordField() && !this.form.controls.password.valid)
    ) {
      console.error(
        "Form is invalid. Email: ",
        this.form.controls.email.valid,
        "Password: ",
        this.showPasswordField() && this.form.controls.password.valid,
      );
      return;
    }

    const email = this.form.controls.email.value;
    assert(email);

    if (!this.showPasswordField()) {
      this.checkIsSSOUser(email);
      return;
    }

    const password = this.form.controls.password.value;
    assert(password);

    this.continueNonSSOLogin(email, password);
  }

  private continueNonSSOLogin(email: string, password: string): void {
    this.loading.set(true);

    this.authenticationService.login(email, password).subscribe({
      next: async () => {
        await this.featureFlagsService.load();
        void this.refreshUserData();
      },
      error: () => {
        this.loading.set(false);
        this.notificationService.error("login.loginError");
      },
    });
  }

  private refreshUserData(): void {
    this.userAccessService.setUserData().subscribe(() => {
      void this.intercomService.init();

      if (this.gtmService.isReloadNeeded()) {
        window.location.href = "/welcome";
      }

      this.router.navigate([this.returnUrl]);
    });
  }

  private checkIsSSOUser(email: string): void {
    this.loading.set(true);
    this.authenticationService.isSSoUser(email).subscribe({
      next: (isSSoUserResponse) => {
        if (isSSoUserResponse.isSSO) {
          void this.startSSOLogin(isSSoUserResponse.brandId);
          return;
        }

        this.form.controls.password.setValidators(Validators.required);
        this.form.controls.password.updateValueAndValidity();
        this.showPasswordField.set(true);

        setTimeout(() => {
          this.passwordField?.nativeElement.focus();
        }, 200);
      },
      error: () => {
        this.notificationService.error("login.loginError");
      },
      complete: () => {
        this.loading.set(false);
      },
    });
  }

  private async startSSOLogin(brandId: number): Promise<void> {
    this.loading.set(true);

    const url = await lastValueFrom(
      this.authenticationService.getSSOLoginUrl(brandId),
    );

    if (!window || !url) {
      this.notificationService.error("login.loginError");
      return;
    }

    this.loading.set(false);
    window.location.href = url;
  }
}
