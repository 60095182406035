import { Component, inject, OnInit, signal } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { ActivatedRoute, Params, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { UserService } from "../../shared/services/api/user.service";
import { NotificationService } from "../../shared/services/notification.service";
import { assert } from "../../shared/utils/assert";
import { CustomFormValidators } from "../../shared/validators/custom-form-validators";

interface RecoverPasswordForm {
  readonly email: FormControl<string | null>;
  readonly password: FormControl<string | null>;
}

@Component({
  standalone: true,
  selector: "app-recover-password",
  templateUrl: "./recover-password.component.html",
  styleUrl: "../auth.scss",
  imports: [
    FormsModule,
    TranslateModule,
    MatFormField,
    MatInput,
    MatLabel,
    RouterLink,
    ReactiveFormsModule,
  ],
})
export class RecoverPasswordComponent implements OnInit {
  protected readonly state = signal<undefined | "submitting" | "submitted">(
    undefined,
  );
  protected readonly form: FormGroup<RecoverPasswordForm>;
  protected token?: string;
  private readonly userService = inject(UserService);
  private readonly notificationService = inject(NotificationService);
  private readonly activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.form = new FormGroup<RecoverPasswordForm>({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        CustomFormValidators.pattern(CustomFormValidators.PASSWORD_PATTERN),
      ]),
    });
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params.token;
    });
  }

  protected recoverPassword(): void {
    if (!this.form.controls.email.valid) {
      this.form.markAsDirty();
      this.form.markAsTouched();
      return;
    }
    const email = this.form.controls.email.value;
    assert(email);

    this.state.set("submitting");
    this.userService.recoverPassword(email).subscribe({
      next: () => {
        this.state.set("submitted");
      },
      error: (err) => {
        this.state.set(undefined);
        const { error } = err.error;

        this.notificationService.error(
          error?.key === "USER_NOT_FOUND"
            ? "recoverPassword.userNotFound"
            : "recoverPassword.canNotRequest",
        );
      },
    });
  }

  protected savePassword(): void {
    if (!this.form.controls.password.valid) {
      return;
    }

    this.state.set("submitting");

    const password = this.form.controls.password.value;
    const token = this.token;
    assert(password);
    assert(token);

    this.userService.setNewPassword(password, token).subscribe({
      next: () => {
        this.state.set("submitted");
      },
      error: () => {
        this.state.set(undefined);
        this.notificationService.error("recoverPassword.canNotSave");
      },
    });
  }
}
